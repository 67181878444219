import React from 'react'
import { Link } from 'gatsby'

import CSS from './footer.module.css'

const Footer = () => {
    return (
        <footer className={CSS.container}>
            © {new Date().getFullYear()} Onedotsix SARL : <Link to="/legal">Legal</Link>
            <script src="https://cdn.usefathom.com/3.js" site="PNAFZXWH"></script>
            <script async defer data-domain="onedotsix.com" src="https://plausible.io/js/plausible.js"></script>
        </footer>
    )
}

export default Footer